import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import GatemasterPOSList from './GatemasterPOSList';
import TicketReaderList from './TicketReaderList';
import KitchenPrinterList from './KitchenPrinterList';
import AgilysysPOSList from './AgilysysPOSList';
import { Container, Row, Col, Form, Button, Tabs, Tab } from 'react-bootstrap';
import styles from '../../CSS/DeviceList.module.css'; // Import CSS Module

export const route = "/it/device/list";
export const role = "IT";

function DeviceList() {
    const [, setDevices] = useState([]);
    const [, setSpares] = useState([]);
    const [groupedDevices, setGroupedDevices] = useState({});
    const [currentTab, setCurrentTab] = useState('Agilysys POS');
    const [sortFilter, setSortFilter] = useState({ status: '', pier: '' });
    const navigate = useNavigate();

    const deviceTypes = ["Agilysys POS", "Gatemaster POS", "Ticket Reader", "Kitchen Printer"];

    const groupDevicesByType = useCallback((devices, spares) => {
        const activeSpareIds = spares
            .filter(spare => !spare.restoreDate)
            .map(spare => spare.spareReader._id);

        const grouped = devices.reduce((acc, device) => {
            if (!acc[device.type]) {
                acc[device.type] = [];
            }
            acc[device.type].push(device);
            return acc;
        }, {});

        // Sort each device type group: Active spares and offline devices to the bottom
        Object.keys(grouped).forEach(type => {
            grouped[type] = grouped[type].sort((a, b) => {
                const aIsActiveSpare = activeSpareIds.includes(a._id);
                const bIsActiveSpare = activeSpareIds.includes(b._id);
                const aIsOffline = !a.isOnline;
                const bIsOffline = !b.isOnline;

                if (aIsActiveSpare && !bIsActiveSpare) return 1;
                if (!aIsActiveSpare && bIsActiveSpare) return -1;
                if (aIsOffline && !bIsOffline) return 1;
                if (!aIsOffline && bIsOffline) return -1;
                return 0;
            });
        });

        setGroupedDevices(grouped);
    }, []);

    const fetchDevices = useCallback(async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/`);
        const data = await response.json();
        setDevices(data);
        return data;
    }, []);

    const fetchSpares = useCallback(async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/spare/deployments/`);
        const data = await response.json();
        setSpares(data);
        return data;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const devicesData = await fetchDevices();
            const sparesData = await fetchSpares();
            groupDevicesByType(devicesData, sparesData);
        };

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 30000); // Refresh every 30 seconds

        return () => clearInterval(interval);
    }, [fetchDevices, fetchSpares, groupDevicesByType]);

    const handleDelete = async (deviceId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this device?");
        if (confirmDelete) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/${deviceId}/`, {
                method: 'DELETE'
            });
            if (response.ok) {
                const devicesData = await fetchDevices();
                const sparesData = await fetchSpares();
                groupDevicesByType(devicesData, sparesData); // Refresh the list after deletion
            } else {
                alert('Failed to delete the device');
            }
        }
    };

    const handleSort = (devices) => {
        return devices.filter(device => {
            if (sortFilter.status) {
                const isOfflineFilter = sortFilter.status === 'offline';
                const deviceIsOffline = !device.isOnline;

                if (isOfflineFilter && !deviceIsOffline) {
                    return false;
                }
                if (!isOfflineFilter && deviceIsOffline) {
                    return false;
                }

                // Exclude spare devices when the offline filter is selected
                if (isOfflineFilter && device.isSpare) {
                    return false;
                }
            }

            if (sortFilter.pier && sortFilter.pier !== device.pier) {
                return false;
            }
            return true;
        });
    };

    const renderDeviceListByType = (type) => {
        switch (type) {
            case 'Ticket Reader':
                return <TicketReaderList devices={handleSort(groupedDevices[type] || [])} handleDelete={handleDelete} />;
            case 'Agilysys POS':
                return <AgilysysPOSList devices={handleSort(groupedDevices[type] || [])} handleDelete={handleDelete} />;
            case 'Kitchen Printer':
                return <KitchenPrinterList devices={handleSort(groupedDevices[type] || [])} handleDelete={handleDelete} />;
            case 'Gatemaster POS':
                return <GatemasterPOSList devices={handleSort(groupedDevices[type] || [])} handleDelete={handleDelete} />;
            default:
                return null;
        }
    };

    return (
        <Container className={`my-5 ${styles.container}`}>
            <Row className="mb-4">
                <Col>
                    <h1>Device List</h1>
                    <Button onClick={() => navigate('/it/device/create')} className="btn btn-primary mt-3">Create +</Button>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={6}>
                    <Form.Group controlId="statusFilter">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            value={sortFilter.status}
                            onChange={(e) => setSortFilter({ ...sortFilter, status: e.target.value })}
                        >
                            <option value="">All</option>
                            <option value="online">Online</option>
                            <option value="offline">Offline</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="pierFilter">
                        <Form.Label>Pier</Form.Label>
                        <Form.Control
                            as="select"
                            value={sortFilter.pier}
                            onChange={(e) => setSortFilter({ ...sortFilter, pier: e.target.value })}
                        >
                            <option value="">All</option>
                            <option value="Mariner's Pier">Mariner's Pier</option>
                            <option value="Surfside Pier">Surfside Pier</option>
                            <option value="Adventure Pier">Adventure Pier</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <Tabs
                        id="device-type-tabs"
                        activeKey={currentTab}
                        onSelect={(k) => setCurrentTab(k)}
                        className="mb-3"
                    >
                        {deviceTypes.map(type => (
                            <Tab eventKey={type} title={type} key={type}>
                                {renderDeviceListByType(type)}
                            </Tab>
                        ))}
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}

export default DeviceList;
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { Container, Button, Badge, Card, Spinner, Alert } from 'react-bootstrap';
import '../../CSS/DeviceDetails.css'; // Create this CSS file for custom styling

const customIcon = new L.Icon({
    iconUrl: 'marker-icon.png',
    iconRetinaUrl: 'marker-icon-2x.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'marker-shadow.png',
    shadowSize: [41, 41]
});

export const route = "/it/device/:deviceId";
export const role = "IT";

function DeviceDetails() {
    const { deviceId } = useParams();
    const [device, setDevice] = useState(null);
    const [feedback, setFeedback] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDeviceDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/${deviceId}/`);
                const data = await response.json();
                if (response.ok) {
                    setDevice(data);
                } else {
                    console.error('Failed to fetch device details');
                }
            } catch (error) {
                console.error('Error fetching device details:', error);
            }
        };

        fetchDeviceDetails();
    }, [deviceId]);

    const handleDelete = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/${deviceId}/`, {
            method: 'DELETE'
        });
        if (response.ok) {
            navigate('/it/device/list'); // redirect to the device list after deletion
        } else {
            alert('Failed to delete the device');
        }
    };

    const handlePing = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/ping/${deviceId}/`);
        const { isOnline } = await response.json();
        if (device.isOnline !== isOnline) {
            setDevice({ ...device, isOnline });
            setFeedback('Device status updated!')
        } else {
            setFeedback('Device status checked, no change!')
        }
    };

    const handleReboot = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/reboot/${deviceId}/`, {
                method: 'GET'
            });
            if (response.ok) {
                setFeedback('Reboot request sent!');
            } else {
                setFeedback('Failed to send reboot request');
            }
        } catch (error) {
            setFeedback('Error sending reboot request');
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    if (!device) return <Spinner animation="border" variant="primary" className="d-block mx-auto my-5" />;

    return (
        <Container className="my-5">
            <Card className="mb-4 shadow-sm">
                <Card.Header className="text-white">
                    <h4>Device Details</h4>
                </Card.Header>
                <Card.Body>
                    <h5 className="card-title">{device.friendlyName}</h5>
                    <p className="card-text"><strong>Type:</strong> {device.type}</p>
                    <p className="card-text"><strong>Name:</strong> {device.name}</p>
                    <p className="card-text"><strong>Location:</strong>
                        <MapContainer center={[device.location.lat, device.location.lng]} zoom={18} style={{ height: '400px', width: '100%' }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={[device.location.lat, device.location.lng]} icon={customIcon}>
                                <Popup>{device.friendlyName}</Popup>
                            </Marker>
                        </MapContainer>
                    </p>
                    <p className="card-text"><strong>Pier:</strong> {device.pier}</p>
                    {device.type === 'Agilysys POS' && (
                        <>
                            <p className="card-text"><strong>Terminal ID:</strong> {device.terminalId}</p>
                            <p className="card-text"><strong>Profile ID:</strong> {device.profileId}</p>
                        </>
                    )}
                    <p className="card-text"><strong>IP:</strong> {device.ip}</p>
                    <p className="card-text"><strong>Status:</strong>
                        <Badge className={`ms-2 ${device.isOnline ? 'bg-success' : 'bg-danger'}`}>
                            {device.isOnline ? 'Online' : 'Offline'}
                        </Badge>
                    </p>
                    <Button variant="primary" onClick={() => navigate(`/it/device/edit/${device._id}`)} className="me-2">Edit</Button>
                    <Button variant="danger" onClick={handleDelete} className="me-2">Delete</Button>
                    <Button variant="info" onClick={handlePing} className="me-2">Check Status</Button>
                    {device.type === 'Ticket Reader' && (
                        <>
                            <Button variant="warning" onClick={handleReboot} className="me-2">Reboot</Button>
                            <Link to={`/it/device/configure/${device._id}`} className="btn btn-primary m-2">
                                Configure Ticket Reader
                            </Link>
                        </>
                    )}
                    {feedback && <Alert variant="info" className="mt-2">{feedback}</Alert>}
                </Card.Body>
            </Card>
            <Card className="mt-4 shadow-sm">
                <Card.Header className="bg-secondary text-white">
                    <h4>Status Log</h4>
                </Card.Header>
                <Card.Body>
                    <div className="timeline">
                        {device.statusLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((log, index) => (
                            <div className="timeline-item" key={index} style={{flexDirection: "column"}}>
                                {index === 0 || formatDate(device.statusLogs[index - 1].timestamp) !== formatDate(log.timestamp) ? (
                                    <div className="timeline-date">
                                        {formatDate(log.timestamp)}
                                    </div>
                                ) : null}
                                <div className="timeline-content" >
                                    <div className="timeline-icon">
                                        {log.status === 'Online' ? (
                                            <svg className="text-success" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 4.146l-8.036 8.036L0 4.146 1.564 2.58l6.4 6.4L14.4 2.58 16 4.146zM1.564 8.82L0 7.256l1.564-1.564 1.564 1.564-1.564 1.564zm3.2-3.2L4.8 4.056 6.364 5.62 4.8 7.184 3.764 6.148zM8.036 5.62L9.6 4.056l1.564 1.564-1.564 1.564-1.564-1.564zm3.2-3.2L12.8 0 14.364 1.564 12.8 3.128l-1.564-1.564zm3.2-3.2L16 0l-1.564 1.564L12.8 3.128 14.364 1.564zm-1.564 4.8l1.564 1.564-1.564 1.564-1.564-1.564 1.564-1.564z" />
                                            </svg>
                                        ) : (
                                            <svg className="text-danger" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 4.146l-8.036 8.036L0 4.146 1.564 2.58l6.4 6.4L14.4 2.58 16 4.146zM1.564 8.82L0 7.256l1.564-1.564 1.564 1.564-1.564 1.564zm3.2-3.2L4.8 4.056 6.364 5.62 4.8 7.184 3.764 6.148zM8.036 5.62L9.6 4.056l1.564 1.564-1.564 1.564-1.564-1.564zm3.2-3.2L12.8 0 14.364 1.564 12.8 3.128l-1.564-1.564zm3.2-3.2L16 0l-1.564 1.564L12.8 3.128 14.364 1.564zm-1.564 4.8l1.564 1.564-1.564 1.564-1.564-1.564 1.564-1.564z" />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="timeline-details">
                                        <span className="timeline-time">{new Date(log.timestamp).toLocaleTimeString()}</span>
                                        <span className={`badge ${log.status === 'Online' ? 'bg-success' : 'bg-danger'}`}>{log.status}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default DeviceDetails;
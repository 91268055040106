import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/Homepage.css';

export const route = "/";

const Homepage = () => {
  return (
    <div className="homepage container mt-5">
      <div className="text-center">
        <h1 className="mb-4">Welcome to Park Pulse</h1>
        <p className="lead mb-5">
          Park Pulse is your go-to platform for managing and monitoring various devices and systems within the park. From ticket readers and POS systems to MIMO cash and coin machines, Park Pulse provides comprehensive insights and management capabilities.
        </p>
      </div>

      <div className="row text-center mb-5">
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Device List</h5>
              <p className="card-text">View and manage all park devices, including ticket readers and POS systems.</p>
              <Link to="/it/device/list" className="btn btn-primary">View Devices</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Micros List</h5>
              <p className="card-text">Monitor and manage Micros devices deployed across the park.</p>
              <Link to="/it/micros/list" className="btn btn-primary">View Micros</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Spare List</h5>
              <p className="card-text">Keep track of spare devices and their deployment history.</p>
              <Link to="/it/spare/list" className="btn btn-primary">View Spares</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4 mx-auto">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Ping Devices</h5>
              <p className="card-text">Ping devices by IP addresses to check their status.</p>
              <Link to="/it/device/ping" className="btn btn-primary">Ping Devices</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <h2 className="mb-4">MIMO Dashboard</h2>
        <p className="lead mb-5">
          The MIMO Dashboard provides detailed information on MIMO cash and coin machines, including recent transactions, users, and current cassette data.
        </p>
      </div>

      <div className="row text-center">
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">MIMO Data</h5>
              <p className="card-text">Get detailed information on the current status and contents of MIMO cassettes.</p>
              <Link to="/mimo/dashboard" className="btn btn-primary">View MIMO Data</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Recent Transactions</h5>
              <p className="card-text">Keep track of all recent transactions processed by MIMO machines.</p>
              <Link to="/mimo/transactions" className="btn btn-primary">View Transactions</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">User Summary</h5>
              <p className="card-text">Keep track of all recent transactions processed by each user.</p>
              <Link to="/mimo/user-summary" className="btn btn-primary">View User Summary</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4 mx-auto">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Register Summary</h5>
              <p className="card-text">View summary of transactions by each register.</p>
              <Link to="/mimo/register-summary" className="btn btn-primary">View Register Summary</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
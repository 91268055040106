import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

const issueTypes = {
    "Ticket Reader": [
        "Light Replacement",
        "Fan Replacement",
        "Rabbit Board Replacement",
        "Uplink Issue",
        "Scanner Issue",
        "Turnstile Issue",
        "LCD Issue",
        "Not Scanning",
        "Other"
    ],
    "Agilysys POS": [
        "Uplink Issue",
        "Scanner Issue",
        "Credit Card Reader Issue",
        "Receipt Printer Issue",
        "Cash Drawer Issue",
        "Software Issue",
        "Power Issue",
        "Display Issue",
        "Other"
    ],
    "Gatemaster POS": [
        "Boca Printer Issue",
        "Reciept Printer Issue",
        "Credit Card Reader Issue",
        "Mouse / Keyboard Issue",
        "Other"
    ],
    "Kitchen Printer": [
        "Uplink Issue",
        "Printing Issue",
        "Power Issue",
        "Routing Issue",
        "Other"
    ]
};

export const route = "/it/report";
export const role = "IT";

const ReportIssue = () => {
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [selectedIssue, setSelectedIssue] = useState('');
    const [customIssue, setCustomIssue] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { user } = useAuth0();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/devices/`)
            .then(res => res.json())
            .then(data => {
                const types = [...new Set(data.map(device => device.type))];
                setDeviceTypes(types);
            })
            .catch(err => console.error("Failed to load device types", err));
    }, []);

    useEffect(() => {
        if (selectedType) {
            fetch(`${process.env.REACT_APP_API_URL}/devices/`)
                .then(res => res.json())
                .then(data => {
                    const filteredDevices = data.filter(device => device.type === selectedType);
                    setDevices(filteredDevices);
                })
                .catch(err => console.error("Failed to load devices", err));
        } else {
            setDevices([]);
        }
    }, [selectedType]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedDevice || !selectedIssue || !description || (selectedIssue === 'Other' && !customIssue)) {
            setError('Please fill in all fields.');
            return;
        }

        const selectedDeviceName = devices.find(device => device._id === selectedDevice).friendlyName;
        const issue = selectedIssue === 'Other' ? customIssue : selectedIssue;

        const payload = {
            subject: `${selectedDeviceName} - ${issue}`,
            message: `${description}\n\n--\n${user.email}`.replace(/\n/g, '\n'),
            to: 'support@moreys.zohodesk.com' // replace with the actual receiver email addresses
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error('Failed to report the issue');
            }

            setSuccess(true);
            setError(null);
            setSelectedType('');
            setSelectedDevice('');
            setSelectedIssue('');
            setCustomIssue('');
            setDescription('');
        } catch (err) {
            setError(err.message);
            setSuccess(false);
        }
    };

    return (
        <Container>
            <h1 className="mt-4">Report an Issue</h1>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="deviceType">
                            <Form.Label>Device Type:</Form.Label>
                            <Form.Control as="select" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                                <option value="">Select Device Type</option>
                                {deviceTypes.map(type => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                {selectedType && (
                    <>
                        <Row className="mb-3">
                            <Col>
                                <Form.Group controlId="device">
                                    <Form.Label>Device:</Form.Label>
                                    <Form.Control as="select" value={selectedDevice} onChange={(e) => setSelectedDevice(e.target.value)}>
                                        <option value="">Select Device</option>
                                        {devices.map(device => (
                                            <option key={device._id} value={device._id}>{device.friendlyName}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Group controlId="issueType">
                                    <Form.Label>Issue Type:</Form.Label>
                                    <Form.Control as="select" value={selectedIssue} onChange={(e) => setSelectedIssue(e.target.value)}>
                                        <option value="">Select Issue Type</option>
                                        {issueTypes[selectedType]?.map(issue => (
                                            <option key={issue} value={issue}>{issue}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        {selectedIssue === 'Other' && (
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group controlId="customIssue">
                                        <Form.Label>Custom Issue Type:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={customIssue}
                                            onChange={(e) => setCustomIssue(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="description">
                            <Form.Label>Description of the issue:</Form.Label>
                            <Form.Control as="textarea" rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" variant="primary">Submit</Button>
            </Form>
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">Issue reported successfully!</Alert>}
        </Container>
    );
};

export default ReportIssue;
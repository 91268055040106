import React, { useState, useEffect, useCallback } from 'react';
import { Form, Spinner, Table, Alert, Row, Col, Pagination } from 'react-bootstrap';
import { format, subDays, subMonths, startOfWeek, startOfMonth } from 'date-fns';

export const route = "/mimo/transactions";
export const role = "MIMO";

function TransactionList() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [dateFilter, setDateFilter] = useState('All');
    const [customStartDate, setCustomStartDate] = useState('');
    const [customEndDate, setCustomEndDate] = useState('');
    const [username, setUsername] = useState('');
    const [terminalId, setTerminalId] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [registerName, setRegisterName] = useState('');
    const [sortField, setSortField] = useState('TRANS_ID');
    const [sortOrder, setSortOrder] = useState('DESC');

    const fetchTransactions = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            let startDate, endDate;
            const today = new Date();
            switch (dateFilter) {
                case 'today':
                    startDate = endDate = format(today, 'yyyy-MM-dd');
                    break;
                case 'yesterday':
                    startDate = endDate = format(subDays(today, 1), 'yyyy-MM-dd');
                    break;
                case 'currentWeek':
                    startDate = format(startOfWeek(today), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'currentMonth':
                    startDate = format(startOfMonth(today), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last3Days':
                    startDate = format(subDays(today, 3), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last7Days':
                    startDate = format(subDays(today, 7), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last15Days':
                    startDate = format(subDays(today, 15), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last30Days':
                    startDate = format(subDays(today, 30), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last3Months':
                    startDate = format(subMonths(today, 3), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'custom':
                    startDate = customStartDate;
                    endDate = customEndDate;
                    if (!startDate || !endDate) {
                        setError('Custom date range requires both start and end dates');
                        setLoading(false);
                        return;
                    }
                    break;
                default:
                    startDate = '1970-01-01';
                    endDate = format(new Date(), 'yyyy-MM-dd');
            }

            // Add a day to the end date for inclusion of the entire day
            const adjustedEndDate = new Date(endDate);
            adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
            const formattedEndDate = adjustedEndDate.toISOString().split('T')[0];

            const url = `${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/transactions/${page}/${limit}/${startDate}/${formattedEndDate}?username=${username}&terminalId=${terminalId}&transactionId=${transactionId}&registerName=${registerName}&sortField=${sortField}&sortOrder=${sortOrder}`;
            const response = await fetch(url, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setTransactions(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [dateFilter, page, limit, customStartDate, customEndDate, username, terminalId, transactionId, registerName, sortField, sortOrder]);

    useEffect(() => {
        fetchTransactions();
    }, [page, limit, dateFilter, customStartDate, customEndDate, username, terminalId, transactionId, registerName, fetchTransactions]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleSort = (field) => {
        setSortField(field);
        setSortOrder('DESC');
    };

    return (
        <div className="container mt-5">
            <h1>Transaction List</h1>
            <Form>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="dateFilter">
                            <Form.Label>Date Filter</Form.Label>
                            <Form.Select value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}>
                                <option value="All">All</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="currentWeek">Current Week</option>
                                <option value="currentMonth">Current Month</option>
                                <option value="last3Days">Last 3 Days</option>
                                <option value="last7Days">Last 7 Days</option>
                                <option value="last15Days">Last 15 Days</option>
                                <option value="last30Days">Last 30 Days</option>
                                <option value="last3Months">Last 3 Months</option>
                                <option value="custom">Custom</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {dateFilter === 'custom' && (
                        <>
                            <Col md={4}>
                                <Form.Group controlId="customStartDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type="date" value={customStartDate} onChange={(e) => setCustomStartDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="customEndDate">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="date" value={customEndDate} onChange={(e) => setCustomEndDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="mb-3">
                    <Col md={3}>
                        <Form.Group controlId="usernameFilter">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="terminalIdFilter">
                            <Form.Label>Terminal ID</Form.Label>
                            <Form.Control type="text" value={terminalId} onChange={(e) => setTerminalId(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="transactionIdFilter">
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Control type="text" value={transactionId} onChange={(e) => setTransactionId(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="registerNameFilter">
                            <Form.Label>Register Name</Form.Label>
                            <Form.Control type="text" value={registerName} onChange={(e) => setRegisterName(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : transactions.length === 0 ? (
                <Alert variant="info">No transactions found</Alert>
            ) : (
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('TRANS_ID')}>Transaction ID</th>
                                <th onClick={() => handleSort('TERMINAL_ID')}>Terminal ID</th>
                                <th onClick={() => handleSort('REGISTER_NAME')}>Register Name</th>
                                <th onClick={() => handleSort('USER_NAME')}>User Name</th>
                                <th>Transaction Name</th>
                                <th>Transaction Total</th>
                                <th>Last Synced</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction) => (
                                <tr key={transaction.TRANS_ID}>
                                    <td>{transaction.TRANS_ID}</td>
                                    <td>{transaction.TERMINAL_ID}</td>
                                    <td>{transaction.REGISTER_NAME}</td>
                                    <td>{transaction.USER_NAME}</td>
                                    <td>{transaction.TRANS_NAME}</td>
                                    <td>${transaction.TRANS_TOTAL.toFixed(2)}</td>
                                    <td>{new Date(transaction._FIVETRAN_SYNCED).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination>
                        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
                        <Pagination.Item active>{page}</Pagination.Item>
                        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={transactions.length < limit} />
                    </Pagination>
                </>
            )}
        </div>
    );
}

export default TransactionList;
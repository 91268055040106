import React, { useState, useEffect } from 'react';
import { format, subDays, addDays } from 'date-fns';
import { Container, Row, Col, Form, Table, Spinner, Alert, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';

export const route = "/mimo/register-summary";
export const role = "MIMO";

const RegisterSummary = () => {
    const today = format(new Date(), 'yyyy-MM-dd');
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [registerId, setRegisterId] = useState('');
    const [registerSummaries, setRegisterSummaries] = useState([]);
    const [registers, setRegisters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchRegisters = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/registers/`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setRegisters(data);
        } catch {
            setError('Failed to fetch registers');
        }
    };

    useEffect(() => {
        fetchRegisters();
    }, []);

    useEffect(() => {
        const fetchRegisterSummary = async () => {
            setLoading(true);
            setError(null);
            try {
                const adjustedStartDate = format(subDays(new Date(startDate), 0), 'yyyy-MM-dd');
                const adjustedEndDate = format(addDays(new Date(endDate), 1), 'yyyy-MM-dd');

                const response = await fetch(`${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/register-summary/${adjustedStartDate}/${adjustedEndDate}/?registerId=${registerId}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true' 
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setRegisterSummaries(data.sort((a, b) => a.REGISTER_ID - b.REGISTER_ID));
            } catch {
                setError('Failed to fetch register summary data');
            } finally {
                setLoading(false);
            }
        };

        fetchRegisterSummary();
    }, [startDate, endDate, registerId]);

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(registerSummaries);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Register Summary');
        const fileName = `Register_Summary_${startDate}_to_${endDate}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    return (
        <Container>
            <h1 className="mt-4">Register Summary</h1>
            <Form>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="startDate">
                            <Form.Label>Start Date:</Form.Label>
                            <Form.Control
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="endDate">
                            <Form.Label>End Date:</Form.Label>
                            <Form.Control
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="registerId">
                            <Form.Label>Register ID:</Form.Label>
                            <Form.Control
                                as="select"
                                value={registerId}
                                onChange={(e) => setRegisterId(e.target.value)}
                            >
                                <option value="">All Registers</option>
                                {registers.map(register => (
                                    <option key={register.REGISTER_ID} value={register.REGISTER_ID}>{register.REGISTER_NAME}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : registerSummaries.length === 0 ? (
                <Alert variant="info">No data found for the selected dates.</Alert>
            ) : (
                <>
                    <Button variant="success" onClick={exportToExcel} className="mb-3">
                        Export to Excel
                    </Button>
                    <Table striped bordered hover className="mt-4 mb-3">
                        <thead>
                            <tr>
                                <th>Register ID</th>
                                <th>Register Name</th>
                                <th>Business Date</th>
                                <th>Dispense Amount</th>
                                <th>Deposit Amount</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {registerSummaries.map((summary, index) => (
                                <tr key={index}>
                                    <td>{summary.REGISTER_ID}</td>
                                    <td>{summary.REGISTER_NAME}</td>
                                    <td>{summary.BUSINESS_DATE}</td>
                                    <td>{summary.DISPENSE_AMOUNT}</td>
                                    <td>{summary.DEPOSIT_AMOUNT}</td>
                                    <td>{summary.TOTAL_AMOUNT}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </Container>
    );
};

export default RegisterSummary;
import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../CSS/Navbar.module.css';
import { Collapse } from 'bootstrap';

function Navbar() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value.length > 1) {
            performSearch(value);
        } else {
            setSearchResults([]);
        }
    };

    const performSearch = async (term) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/search?term=${term}`);
        const data = await response.json();
        if (response.ok) {
            setSearchResults(data);
        } else {
            setSearchResults([]);
        }
    };

    const handleSearchSelect = () => {
        setSearchTerm('');
        setSearchResults([]);
    };

    const handleNavLinkClick = () => {
        // Collapse the navbar after a link is clicked
        const navbar = document.getElementById('navbarSupportedContent');
        if (navbar && navbar.classList.contains('show')) {
            new Collapse(navbar).hide();
        }
    };

    return (
        <nav className={`navbar navbar-expand-lg ${styles.navbar}`}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/" onClick={handleNavLinkClick}>
                    <img src="logo.png" alt="Logo" width="105" height="60" className="d-inline-block align-text-center" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={`nav-link ${styles.navLink}`} to="/" onClick={handleNavLinkClick}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${styles.navLink}`} to="/it" onClick={handleNavLinkClick}>IT</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${styles.navLink}`} to="/mimo" onClick={handleNavLinkClick}>MIMO</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${styles.navLink}`} to="/officer" onClick={handleNavLinkClick}>Officer Check-In</Link>
                        </li>
                    </ul>
                    <div className="d-flex position-relative search-container">
                        <input className={`form-control me-2 ${styles.searchInput}`} type="search" placeholder="Search" aria-label="Search" value={searchTerm} onChange={handleSearchChange} />
                        {searchResults.length > 0 && (
                            <div className={`search-results position-absolute bg-white border rounded w-100 ${styles.searchResults}`}>
                                {searchResults.map((device) => (
                                    <Link
                                        to={`/it/device/${device._id}`}
                                        key={device._id}
                                        className="dropdown-item"
                                        onClick={() => { handleSearchSelect(); handleNavLinkClick(); }}
                                    >
                                        {device.friendlyName} - {device.type}
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="ms-3">
                        {!isAuthenticated ? (
                            <button className={`btn ${styles.loginButton}`} onClick={() => loginWithRedirect()}>Login</button>
                        ) : (
                            <div className="dropdown">
                                <button className={`btn dropdown-toggle ${styles.userButton}`} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={user.picture} alt={user.name} width="30" height="30" className="rounded-circle" />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                    <li><button className="dropdown-item" onClick={() => logout({ returnTo: window.location.origin })}>Logout</button></li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;